<template>
  <page :footer='65' :styleWrap='{background: "#f6f6f6"}'>

    <div>
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div v-if='managerList.length'>
        <div style="height: 8vh; background: #fff; box-sizing: border-box; padding-right: 12px" class="f_flex f_a_c">
          <div class="f_g1">
            <van-search shape='round' v-model='searchWord' placeholder="搜索"></van-search>
          </div>
          <div class="theme_c" @click="$router.back()">取消</div>
        </div>
        <div class="content" ref='content'>
      
            <div class="allPanel">
              <checkbox v-model='all' v-if='$route.query.delGroupItem' @click="allPanel">
                <div class="f_flex f_a_c">
                  <div class="avatar f_flex f_a_c f_j_c theme_bg">ALL</div>
                  <div>
                    <div class="name">全员</div>
                  </div>
                </div>
              </checkbox>
            </div>


            <checkbox-group v-model="chooseList" ref='checkboxGroup'>
              <van-cell v-for='(ii, k) in managerList' :key='k'>
                  <template #title>
                    <checkbox :name="ii.userId">
                      <div class="f_flex f_a_c">
                        <div class="avatar f_flex f_a_c f_j_c theme_bg">
                          <img v-if='ii.avatar' style="width: 100%; height: 100%" :src="ii.avatar" alt="">
                          <div v-else>{{ii.userName[0]}}</div>
                        </div>
                        <div>
                          <div class="name">{{ii.userName}}</div>
                          <div class="desc">{{ii.departmentName}}</div>
                        </div>
                      </div>
                    </checkbox>
                  </template>
                </van-cell>
            </checkbox-group>
      
          <div style="height: 100px"></div>
        </div>
      </div>
      
    </div>
    <div slot='footer' class="footer f_flex f_a_c f_j_e">
        <button @click='confirmFn' class="btn btn_radius theme_bg" style="width: 114px">确定</button>
      </div>
  </page>
</template>

<script>
import {  Search, Cell, CheckboxGroup, Checkbox } from 'vant';

import groupApi from '@/api/group'
import {mapActions} from 'vuex'

export default {
  components: {

    'van-search': Search,
    'van-cell': Cell,
    CheckboxGroup,
    Checkbox
  },
  data: ()=> ({

    searchWord: '',
    chooseList: [],
    all: false,
    list: [],
    originList: [],
    managerList: []
  }),
  watch: {
    chooseList(n) {
      if (n.length === this.originList.length) {
        this.all = true
      } else {
        this.all = false
      }
    }
  },
  computed: {
   
    getSubmitData() {
      const list = this.originList
      const choose = this.chooseList
      return list.filter(item => {
        return choose.includes(item.userId)
      })
    }
  },
  methods: {
    ...mapActions([
      'set_anchorList',
      'set_anchorType', // 1 不传 2 传一些  3全部
    ]),
    // 全选
    allPanel() {
      if (this.all) {
        this.$refs.checkboxGroup.toggleAll(true);
      } else {
        this.$refs.checkboxGroup.toggleAll();
      }
    },
    // 单选某一个
    pickItem(e) {
      if (e === '#') {
        const all = '#-0'
        const isAll = this.chooseList.some(item => item === all)
        if (isAll) {
          this.$refs.checkboxGroup.toggleAll(true);
        } else {
          this.$refs.checkboxGroup.toggleAll();
        }
      }
    },
    
    getManagerList() {
      groupApi.groupManagerInfo({
        groupId: this.$route.query.groupId
      }).then(res => {
        if (res.data) {
          this.managerList = res.data.groupUserList
          this.originList = res.data.groupUserList
          
        } 
      })
    },
    // 改造返回的list 使之可以被应用
    dealChangeList(list) {
      const map = {}
      list.forEach(item => {
        const anchor = item.firstLetter
        if (map[anchor]) {
          map[anchor].push(item)
        } else {
          map[anchor] = [item]
        }
      });
      const anchorList = []
      for(let i in map) {
        anchorList.push({
          anchor: i,
          list: map[i]
        })
      }
      // [{anchor: 'A', list: [...]}]
      return anchorList
    },
    confirmFn() {
      this.set_anchorList(this.getSubmitData)
      
       const arr = []
        this.getSubmitData.forEach(item => {
          arr.push(item.userId)
        });
        this.deleteGroupManager(arr)
    },
    deleteGroupManager(arr) {
      const {groupId} = this.$route.query
      const obj = {
        groupId: groupId,
        
        type: 2,
        userIdList: arr
      }
      groupApi.setGroupManager(obj).then(res => {
        if (res.data) {
          this.$toast(res.data.msg)
          setTimeout(() => {
            this.$router.back()
          }, 20)
        }
      })
    }
  },
  mounted() {
    this.getManagerList()
  }
}
</script>

<style lang="less" scoped>
.content {
  position: absolute;
  height: 92vh;
  top: 15.9vh;
  width: 100%;
  overflow: auto;
}
.allPanel {
  background: #fff;
  padding: 18px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  overflow: hidden;
  margin-right: 10px;
}
.name {
  font-size: 16px;
  color: #2D2D2D;
  line-height: 18px;
}
.desc {
  font-size: 12px;
  color: #999999;
}
.footer {
  height: 100%;
  background: #fff;
  padding: 12px;
  box-sizing: border-box;
}
</style>